import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
/*
for resume
create flag {t(en)} or ru
then if en load component resumeEn or else ru
 */
i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        interpolation: { escapeValue: false },
        // Allowed languages
        supportedLngs: ["en", "ru"],
        // resources: {
        //     en: {
        //         common: {"welcome":"welcome"}
        //     },
        //     ru: {
        //         common: {"welcome":"Hi"}
        //     }
        // },
        // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        detection: {
            order: ["path", "localStorage", "htmlTag", "cookie"],
            caches: ["localStorage", "cookie"], // cache user language on
        },
        backend: {
            loadPath: 'assets/locales/{{lng}}/common.json',
        }
    });