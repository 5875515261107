import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import propTypes from 'prop-types';
// components:
import { Avatar, Range, Title, Descr } from '../resume/UI/';

// icons:
import { ReactComponent as PrintIcon } from '../../icons/print.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as RegionIcon } from '../../icons/region.svg';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';

const ResumeEn = () => {
  const componentRef = useRef();
  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="ui-wrapper" id="resume">
      <div className="ui-content-wrapper">
        <Wrapper>
          <Header onClick={handlePrintClick} />
          <div className="ui-container" ref={componentRef}>
            <Row itemsCenter>
              {/*<Sidebar>*/}
              {/*  <Avatar />*/}
              {/*</Sidebar>*/}
              <Content>
                <Title>Alexander Sergeevich Mikirtychev</Title>
                <p className="headText">
                  Efficient web developer with 10+ years experience, enterprise
                  architect and private sector business. My proficiency in
                  JavaScript, NodeJS, MySQL, MongoDB, PHP, HTML, CSS, Frontend
                  and Backend technologies. Optimising interactive,
                  user-friendly, and feature-rich websites. Analytical skills
                  and strong atention to detail. Provide technical knowledge and
                  expertise, build new websites from start to finish.
                  Successfully colaborated with project managers and creative
                  teams. Always interested in exploring new opportunities, and
                  improving skills in the field of technology development.
                  <BreakLine />
                </p>
                <a href="https://alexsmik.github.io">Github Page</a>
              </Content>
            </Row>
            <Row>
              <Content>
                {/*<Title size='3' isUppercase>*/}
                {/*  Education:*/}
                {/*</Title>*/}
                {/*<Descr>Stanford University - BS Electrical Engineering</Descr>*/}
                <Title size="3" isUppercase>
                  Work experience:
                </Title>
                <Descr>
                  <b>Entrepreneur</b> <DataExp>08/2010 - by now</DataExp>
                  <br />
                  Web Developer
                  <br />
                  • Websites development. • UX and UI design. • Project
                  management. • Conducted market research and analysis.
                  <BoldLine />
                </Descr>
                <Descr>
                  <b>Аdvertising Аgency “Аccent”</b>
                  <DataExp>09/2010 - 06/2012</DataExp>
                  <br /> Designer
                  <br />• Developed projects - brochures, catalogs,
                  presentations, sites. • Frequently integrated photographic,
                  typographic, and design elements. • Used inDesign, Ilustrator,
                  Photoshop daily to produce.
                  <BoldLine />
                </Descr>
                <Descr>
                  <b>Printing house “Agropack”</b>{' '}
                  <DataExp>10/2006 - 08/2010</DataExp>
                  <br />
                  Graphic Designer
                  <br />
                  • Created a high volume of layout, graphic, and production
                  design. • Developed projects - brochures, catalogs,
                  presentations, sites. • Frequently integrated photographic,
                  typographic, and design elements. • Used inDesign, Ilustrator,
                  Photoshop daily to produce.
                  <BoldLine />
                </Descr>
              </Content>

              <Sidebar>
                <WrapBlur>
                  <Title size="3" isUppercase>
                    Full-Stack Developer
                  </Title>
                  <Descr isPrimary>
                    <RegionIcon
                      style={{
                        marginRight: '0.6rem',
                        width: '1rem',
                        opacity: '0.5',
                      }}
                    />
                    Moscow, Russia
                    <br />
                    <MailIcon style={{ marginRight: '0.6rem' }} />
                    alexsmik@yandex.ru
                    <br />
                    <PhoneIcon style={{ marginRight: '0.6rem' }} />
                    +7 (xxx) xxx xx xx
                  </Descr>
                  <Title size="3" isUppercase>
                    Skills:
                  </Title>
                  <Descr>
                    JavaScript (ES5/ES6), PHP
                    <br />
                    HTML, CSS, Sass
                    <br />
                    ReactJS, NextJS, Express, Bootstrap
                    <br />
                    NodeJS, SQL, NoSql
                  </Descr>
                </WrapBlur>
              </Sidebar>
            </Row>
          </div>
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
};
const Header = ({ onClick }) => {
  return (
    <header className="Header">
      <div className="ui-container">
        <div className="Header_content">
          <button className="ui-button isLink" onClick={onClick}>
            <PrintIcon style={{ marginRight: '0.6rem' }} />
            Print
          </button>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  onClick: propTypes.func,
};

Header.defaultProps = {
  onClick: () => {},
};
const Footer = () => {
  return (
    <footer className="Footer">
      <div className="ui-container">
        <a
          className="ui-link"
          href="https://github.com/alexsmik/"
          target="_blank"
        >
          Github Page
        </a>
      </div>
    </footer>
  );
};
const Wrapper = styled.div`
  max-width: 900px;
  margin: 2rem auto;
  padding: 3rem 2rem;
  background-color: white;
  box-shadow: 0 3px 0 #ddd;
  border-radius: 5px;
`;
const WrapBlur = styled.div`
  color: #606060;
`;
const Row = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'start')};
  margin: 0rem 0;
`;

const Sidebar = styled.div`
  flex: 1;
  margin-right: -3rem;
`;

const Content = styled.div`
  flex: 3;
  margin-left: 1rem;
`;
const BreakLine = styled.hr`
  width: 100%;
  height: 1px;
  background: #fff;
  margin: 0.5rem 0rem;
`;
const BoldLine = styled.hr`
  width: 100%;
  height: 2px;
  background: #bababa;
  margin: 0rem 0rem;
  border: #efefef 3px solid;
`;
const DataExp = styled.b`
  float: right;
  margin: 0rem 0.1rem 0rem 0rem;
  background-color: white;
  box-shadow: 0 1px 0 #ddd;
  border-radius: 1px;
`;
export default ResumeEn;
