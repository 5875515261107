import React from 'react';
import './App.css';
import '../src/Components/gallery/gallerylightbox.css';
import TimeLine from './Components/timeline/TimeLine';
import { BrowserRouter, createBrowserRouter, Link } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import Navbar from './Components/navbar/Navbar';
import PageContact from './PageContact';
import Resume from './Components/resume/Resume';
import i18n from 'i18next';
import { useTranslation, initReactI18next, Trans } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import './services/i18n';
import { Descr } from './Components/resume/UI';
import ResumeEn from './Components/resume/ResumeEn';
import PageResume from './PageResume';

function App() {
 

  return (
    <div>
      <Navbar />
      {/*<h2>{t('welcome')}</h2>*/}

      {/*<Trans i18nKey="welcomeUser">*/}
      {/*    EnHello <strong>lalala</strong>. <Link to="/inbox">See my profile</Link><br/>*/}
      {/*</Trans>*/}

      {/*<Trans i18nKey="multiline">*/}
      {/*    <b>EnSome</b> <em>newlines</em> <br/> br... would be <br/>br... fine<br/>*/}
      {/*</Trans>*/}
      {/*<Trans i18nKey="list_map">*/}
      {/*    EnMy dogs are named:*/}
      {/*    <ul i18nIsDynamicList>*/}
      {/*        {['Enrupert', 'Enmax'].map(dog => (<li>{dog}</li>))}*/}
      {/*    </ul>*/}
      {/*</Trans>*/}

      {/*<Trans*/}
      {/*    i18nKey='dev1'*/}
      {/*    default='<ital>Разработчик</ital><br/>*/}
      {/*    • Разработка сайтов.<br/>*/}
      {/*    • Дизайн графических элементов.<br/>*/}
      {/*    • Управление командой из шести фрилансеров.<br/>*/}
      {/*    • Управление проектами.<br/>*/}
      {/*    • Маркетинговое сопровождение и аналитика.<br/>'*/}
      {/*    components={{ital: <em/>, br:<br/>}}*/}
      {/*>*/}
      {/*    <em>Разработчик</em><br/>*/}
      {/*    • Разработка сайтов.<br/>*/}
      {/*    • Дизайн графических элементов.<br/>*/}
      {/*    • Управление командой из шести фрилансеров.<br/>*/}
      {/*    • Управление проектами.<br/>*/}
      {/*    • Маркетинговое сопровождение и аналитика.<br/>*/}
      {/*</Trans>*/}
      <Routes>
        <Route exact path="/" element={<TimeLine />} />
        <Route exact path="resume" element={<PageResume />} />
        <Route exact path="contact" element={<PageContact />} />
      </Routes>
      {/*<TimeLine />*/}
      {/*<Resume />*/}
      {/*<PageContact />*/}

      {/*<TimeLine />*/}
      {/*<Nav />*/}
      {/*<TimeLine />*/}
      {/*<GalleryLightBox/>*/}
      {/*<Resume/>*/}
    </div>
  );
}

export default App;
