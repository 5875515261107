import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import 'uimini';
import App from './App';
import {IntlProvider} from "react-intl";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {BrowserRouter} from "react-router-dom";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";
import TimeLine from "./Components/timeline/TimeLine";
import Resume from "./Components/resume/Resume";
import PageContact from "./PageContact";
import Navbar from "./Components/navbar/Navbar";
// const router = createBrowserRouter([
//     { path: "/", element: <App /> },
//     { path: "/resume", element: <Resume /> },
//     { path: "/contact", element: <PageContact /> },
// ]);
const root = createRoot(document.getElementById('root'));

root.render(
    // <RouterProvider router={router} />

        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <App />
            </Suspense>
        </BrowserRouter>
);
