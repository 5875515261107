import silvero from '../../images/portfolioimg/silvero.jpg';
import avto from '../../images/portfolioimg/avto.jpg';
import marfin from '../../images/portfolioimg/marfin.jpg';
import ekom from '../../images/portfolioimg/ekom.jpg';
import feniks from '../../images/portfolioimg/feniks.jpg';
import sp from '../../images/portfolioimg/sp.jpg';
import mebrest from '../../images/portfolioimg/mebrest.jpg';
import sverch from '../../images/portfolioimg/sverch.jpg';
import vorota from '../../images/portfolioimg/vorota.jpg';
import poligraf1 from '../../images/portfolioimg/poligraf1.jpg';
import poligraf4 from '../../images/portfolioimg/poligraf4.jpg';
import poligraf6 from '../../images/portfolioimg/poligraf6.jpg';
import kbabru from '../../images/portfolioimg/kbabru.jpg';
import shoppingCart from '../../images/portfolioimg/shoppingCart.jpg';
import shop from '../../images/portfolioimg/shop.jpg';
import panel from '../../images/portfolioimg/panel.jpg';
import auth from '../../images/portfolioimg/auth.jpg';

let timelineElements = [
  {
    id: 1,
    image: shop,
    link: 'https://shop.totun.ru/',
    linktxt: 'shop.totun.ru',
    title: 'Магазин - E-Commerce',
    description: 'ReactJS, NodeJS-Express, MongoDB, Redux, AntDesign',
    buttonText: 'https://shop.totun.ru/',
    date: '2023',
    icon: 'git',
  },
  {
    id: 2,
    image: panel,
    link: 'https://panel.totun.ru/',
    linktxt: 'panel.totun.ru',
    title: 'Панель управления - Dashboard Panel',
    description:
      'ReactJS, NodeJS-Express, MongoDB, Redux, MUI - React UI tools',
    buttonText: 'https://panel.totun.ru/',
    date: '2023',
    icon: 'git',
  },
  {
    id: 3,
    image: auth,
    link: 'https://auth.totun.ru/',
    linktxt: 'auth.totun.ru',
    title: 'Система Авторизации - Authentication System',
    description: 'ReactJS, NodeJS-Express, JWT, MongoDB, HTML, CSS',
    buttonText: 'https://auth.totun.ru/',
    date: '2023',
    icon: 'git',
  },
  {
    id: 4,
    image: shoppingCart,
    link: 'https://alexsmik.github.io/redux-toolkit-shopping-cart/',
    linktxt: 'github.io/redux-toolkit-shopping-cart',
    title: 'Корзина магазина товаров - Shopping Cart E-Commerce',
    description: 'ReactJS, Redux-Toolkit, Styled-Components',
    buttonText: 'https://alexsmik.github.io/redux-toolkit-shopping-cart/',
    date: '2022',
    icon: 'git',
  },
  {
    id: 5,
    image: kbabru,
    link: 'http://kbab.ru/',
    linktxt: 'www.kbab.ru',
    title: 'Дизайн интерьера, архитектурное проектирование',
    description: 'Разработка, СЕО продвижение',
    buttonText: 'http://kbab.ru/',
    date: '2018 - 2022',
    icon: 'web',
  },
  {
    id: 6,
    image: marfin,
    link: 'https://www.marfinohotel.ru/',
    linktxt: 'www.marfinohotel.ru',
    title: 'Отель "Марфино"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: 'https://www.marfinohotel.ru/',
    date: '2016 - 2019',
    icon: 'web',
  },
  {
    id: 7,
    image: sverch,
    link: 'https://www.sverchkov8.com/',
    linktxt: 'www.sverchkov8.com',
    title: 'Отель "Сверчков"',
    description: 'Дизайн, Разработка, Админ панель, СЕО продвижение',
    buttonText: 'Company Website',
    date: '2012 - 2018',
    icon: 'web',
  },
  {
    id: 8,
    image: sp,
    link: 'http://uks-sp.ru/',
    linktxt: 'www.uks-sp.ru',
    title: 'У.К.С. г. Сергиев Посад',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: 'http://uks-sp.ru/',
    date: '2014 - 2018',
    icon: 'web',
  },
  {
    id: 9,
    image: avto,
    link: '',
    title: 'Автосервис "Автотун"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: ' ',
    date: '2010 - 2014',
    icon: 'web',
  },
  {
    id: 10,
    image: silvero,
    link: 'http://www.silvero.ru/',
    linktxt: 'www.silvero.ru',
    title: 'Ювелирная мастерская "Сильверо"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: ' ',
    date: '2010 - 2018',
    icon: 'web',
  },
  {
    id: 11,
    image: ekom,
    title: 'ГК "Строй Спектр"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: ' ',
    date: '20010 - 2014',
    icon: 'web',
  },
  {
    id: 12,
    image: feniks,
    link: '',
    title: 'Промышленная Безопасность "Феникс"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение',
    buttonText: 'College Projects',
    date: '2008 - 2012',
    icon: 'web',
  },
  {
    id: 13,
    image: mebrest,
    link: '',
    title: 'Мастерская по реставрации мебели "Мебрест"',
    description: 'Дизайн, Разработка, Полиграфия, СЕО продвижение ',
    buttonText: ' ',
    date: '2010 - 2014',
    icon: 'web',
  },
  {
    id: 14,
    image: vorota,
    link: '',
    title: 'Продажа откатных ворот',
    description: 'Дизайн, Разработка, СЕО продвижение ',
    buttonText: ' ',
    date: '2008 - 2010',
    icon: 'web',
  },
  {
    id: 15,
    image: poligraf1,
    link: '',
    title: 'Полиграфия "Буклеты"',
    description: 'Дизайн, Верстка',
    buttonText: 'College Projects',
    date: '2006 - 2010',
    icon: 'print',
  },
  {
    id: 16,
    image: poligraf4,
    link: '',
    title: 'Полиграфия "Этикетки"',
    description: 'Дизайн, Верстка ',
    buttonText: 'College Projects',
    date: '2006 - 2010',
    icon: 'print',
  },
  {
    id: 17,
    image: poligraf6,
    link: '',
    title: 'Полиграфия "Плакаты"',
    description: 'Дизайн, Верстка ',
    buttonText: ' ',
    date: '2006 - 2010',
    icon: 'print',
  },
];

export default timelineElements;
