import React from 'react';
import {useTranslation} from "react-i18next";
import Resume from "./Components/resume/Resume";
import ResumeEn from "./Components/resume/ResumeEn";

const PageResume = () => {
    const { i18n } = useTranslation();

    return (
        <div>
            {i18n.language === "ru" ? (<Resume />) : (<ResumeEn />) }
        </div>
    );
};

export default PageResume;
