import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import React, { useState } from 'react';

const MyComponent = () => {
  const currentLocale = Cookies.get('i18next') || 'ru';
  const [language, setLanguage] = useState(currentLocale);
  const handleChangeLocale = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
    console.log('i18n -------> ', i18n);
  };
  const { i18n } = useTranslation();

  const languages = [
    { name: 'English', code: 'en' },
    { name: 'Russian', code: 'ru' },
  ];
  return (
    <>
      <select onChange={handleChangeLocale} value={language}>
        {languages.map(({ name, code }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    </>
  );
};

export default MyComponent;
